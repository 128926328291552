@charset "utf-8";
/* CSS Document */

@font-face{
    font-family:"FuturaPT Bold";
    src:url("../fonts/1059692/1a34942e-33ed-43bb-b229-7460d55b49f7.woff2") format("woff2"),url("../fonts/1059692/baecea54-cfd3-4578-8717-abf89eba62b6.woff") format("woff");
}
@font-face{
    font-family:"FuturaPT Book";
    src:url("../fonts/1059698/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2") format("woff2"),url("../fonts/1059698/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff") format("woff");
}
@font-face{
    font-family:"FuturaPT Medium";
    src:url("../fonts/1059752/f7552419-a773-41ab-ae4a-b12b1d853f02.woff2") format("woff2"),url("../fonts/1059752/83e72918-97c3-41cd-8a7d-4056788a00f0.woff") format("woff");
}


*, *:after, *:before{margin:0;padding:0;box-sizing:border-box}
*:focus{outline:none}
html{font-size:10px}
html, body{min-height:100%;height:100%}
body{background:#fff;color:#38424b;min-width:27rem;font-family:'FuturaPT Book', Arial, Helvetica, sans-serif;font-size:2.4rem;line-height:1.25;-webkit-font-smoothing:antialiased;-webkit-overflow-scrolling:touch;}
a{text-decoration:none;transition:all .2s linear}
a:focus{outline:none}
img{border:none;display:block}
ul li{list-style:none}
input, textarea, select{background-color:transparent;border:none;border-bottom:.1rem solid #d7d9db;color:#38424b;font-family:'FuturaPT Book', Arial, Helvetica, sans-serif;font-size:1.8rem;padding:0;height:5.3rem;width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none}
input:focus, input:active, textarea:focus, textarea:active, select:focus, select:active{border-bottom-color:#8ec051}
textarea{color:#38424b;display:block;font-family:'FuturaPT Book', Arial, Helvetica, sans-serif;font-size:1.8rem;resize:none;padding:16px 0}
button{background-color:#00574a;border:none;color:#fff;font-family:'FuturaPT Book', Arial, Helvetica, sans-serif;margin:0;text-align:center;text-transform:uppercase}
input, input:focus, button, button:focus, select, select:focus{outline:none}
button, input[type="submit"], input[type="button"]{border:none;cursor:pointer}
figure{margin:0}
svg{display:block}
address{font-style:normal}
::-webkit-input-placeholder{color:#888d93}
::-moz-placeholder{color:#888d93}
:-moz-placeholder{color:#888d93}
:-ms-input-placeholder{color:#888d93}

h1, h2, h3, h4, h5, h6{font-weight:normal;margin:0}
h1{color:#00574a;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:6rem;margin-bottom:4rem;text-transform:uppercase}
h2{font-family:'FuturaPT Bold', Arial, Helvetica, sans-serif;font-size:6rem;margin-bottom:6rem;text-transform:uppercase;text-align:center}
h3{color:#8ec051;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:3rem;margin-bottom:2.5rem;text-transform:uppercase}
sub{font-size:70%;bottom:-.3rem}
h1 sub{bottom:-.8rem}

.container{padding:0 4.8rem}
.container-fixed{width:100%;max-width:122rem;margin:0 auto}
.medium-bold{font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;}
.position-relative{position:relative}
.bold{font-family:'FuturaPT Bold', Arial, Helvetica, sans-serif;}
.btn{background-color:#00574a;color:#fff;display:block;font-size:2.4rem;width:23rem;height:6rem;text-align:center;text-transform:uppercase}
button.btn{padding:0}
button.btn:disabled{background-color:#ccdddb}
a.btn{line-height:6rem}
.btn-light{background-color:#8ec051}
.btn-black{background-color:#2e353b;color:#fff}

.brochure-label{position:fixed;top:50%;right:-12.1rem;transform:translateY(-50%) rotate(-90deg);background-color:#8ec051;color:#00574a;display:block;font-size:2rem;width:29rem;height:4.8rem;line-height:4.8rem;padding-left:6rem;text-align:center;z-index:22}
.brochure-label:before{background-color:#00574a;content:'';display:block;width:6rem;height:4.8rem;position:absolute;top:0;left:0}
.brochure-label:after{background:url(../img/icons/ic-pdf-file.svg) 0 0 no-repeat;content:'';display:block;width:2rem;height:2.8rem;position:absolute;top:1rem;left:1.9rem;transform:rotate(90deg)}
.brochure-label.active{background-color:#00574a;color:#fff}
.welcome{background:url(../img/bg-main.jpg) 50% 0 no-repeat;background-size:cover;color:#fff;display:flex;flex-direction:column;justify-content:center;font-size:3rem;min-height:100vh;padding:15rem 4.8rem;text-align:center;position:relative}
.header{background-color:#fff;display:flex;justify-content:center;align-items:flex-start;margin-bottom:8rem;padding:4rem 27.8rem 0;height:19.9rem;position:relative;transition:all .3s linear}
.header:after{background-color:#d7d9db;content:'';display:block;width:calc(100% - 9.6rem);height:.1rem;position:absolute;bottom:0;left:4.8rem}
.header-logo{position:absolute;top:4rem;left:4.8rem}
.welcome .header{background-color:transparent;position:absolute;top:0;left:0;width:100%;height:auto;z-index:20;border-bottom:none;margin-bottom:0}
.welcome .header:after{display:none}
.header-logo img{width:8rem;height:auto}
.welcome .header-logo .logo-gr{display:none}
.header-map{background:url(../img/icons/ic-map.svg) 50% 0 no-repeat;background-size:20px 26px;display:block;padding-top:3.8rem;position:absolute;top:41px;left:50%;transform:translateX(-50%)}
.header-menu ul{display:flex;font-size:2.4rem}
.header-menu li{margin:0 2.5rem;position:relative}
.header-menu a{color:#38424b}
.header-menu .active a{color:#00574a !important;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;}
.header-menu .active:after{background-color:#00574a;content:'';display:block;width:100%;height:.1rem;position:absolute;bottom:-1.7rem;left:0}
.welcome .header-menu a{color:#fff}
.header .btn{position:absolute;top:4rem;right:4.8rem}
.btn-mobile{background-color:#38424b;display:none;width:5rem;height:4rem;padding:.1rem 1rem;position:absolute;top:2.3rem;right:2.5rem}
.btn-mobile i{background-color:#fff;display:block;width:100%;height:.2rem;margin:.8rem 0}
.btn-brochure{display:none;background-color:#8ec051;color:#00574a;font-size:2rem;width:29rem;height:4.8rem;line-height:4.8rem;padding-right:6rem;text-align:center;position:absolute;bottom:1.5rem;left:50%;transform:translateX(-50%)}
.btn-brochure:before{background-color:#00574a;content:'';display:block;width:6rem;height:4.8rem;position:absolute;top:0;right:0}
.btn-brochure:after{background:url(../img/icons/ic-pdf-file.svg) 0 0 no-repeat;content:'';display:block;width:2rem;height:2.8rem;position:absolute;top:1rem;right:1.9rem}
.welcome-center h1{color:#fff;font-family:'FuturaPT Book', Arial, Helvetica, sans-serif;display:inline-block;margin-bottom:1rem;text-transform:none}
.welcome-center .h1-top{display:block;font-size:3rem;margin-bottom:3rem;text-align:left}
.welcome-center .h1-main{display:block;font-family:'FuturaPT Bold', Arial, Helvetica, sans-serif;font-size:10rem;line-height:1;text-transform:uppercase}
.welcome-center .h1-bottom{color:#9ad05a;font-size:6rem;line-height:1.5}
.welcome-center p{max-width:63rem;margin:0 auto 2.5rem}
.pic-view3D{width:23.3rem;position:absolute;bottom:16rem;left:4.8rem}
.pic-view3D:before{background-color:rgba(46,53,59,.2);content:'';display:block;width:100%;height:100%;position:absolute;top:0;left:0}
.pic-view3D img{width:100%}
.icon-3D.dark{background:#2e353b url(../img/icons/ic-3d_w.svg) 50% 50% no-repeat; background-size:4rem 2.7rem;display:block;width:5.5rem;height:4.5rem;position:absolute;right:1rem;bottom:1rem}
.welcome-bottom, .surfaces-bottom{display:flex;font-size:2.4rem;height:9rem;width:100%;padding:0 4.8rem;position:absolute;bottom:4rem;left:0}
.welcome-bottom_main{background-color:#00574a;color:#8ec051;display:flex;justify-content:center;align-items:center;width:34%;height:100%;font-size:3rem;padding:0 1.5rem;text-align:left}
.welcome-bottom .number{font-family:'FuturaPT Bold', Arial, Helvetica, sans-serif}
.welcome-bottom .number sub{bottom:0}
.welcome-bottom_main .number sub{bottom:.4rem}
.welcome-bottom_item{background-color:#8ec051;color:#00574a;display:flex;justify-content:center;align-items:center;width:33%;padding:0 1.5rem;position:relative;text-align:left}
.welcome-bottom .welcome-bottom_item:last-child:before{background-color:rgba(0,87,74,0.5);content:'';display:block;width:.1rem;height:6rem;position:absolute;top:50%;left:0;transform:translateY(-50%)}
.surfaces{min-height:120rem}
.surfaces-bottom_main{background-color:#00574a;margin-right:1rem;flex-grow:2}
.btn-plans{background-color:#8ec051;width:41rem;height:6rem;margin:1.4rem 0;position:absolute;top:0;left:50%;transform:translateX(-50%)}
.btn-plans:after{background:url(../img/icons/ic-arrow.svg) 0 0 no-repeat;background-size:cover;content:'';display:block;width:2rem;height:1.1rem;position:absolute;top:50%;right:3rem;transform:translateY(-50%) rotate(180deg)}
.surfaces-bottom_info{font-size:1.4rem;display:flex;flex-direction:column;align-items:center;letter-spacing:.1rem;position:absolute;left:8rem;top:.5rem}
.surfaces-bottom_info p{font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:6rem;line-height:1}
.surfaces-paging{background-color:#00574a;width:9rem;position:relative}
.surfaces-paging_up{background:url(../img/icons/ic-arrow.svg) 50% 50% no-repeat;background-size:2rem 1.1rem;width:9rem;height:4.5rem;transform:rotate(180deg);display:block}
.surfaces-paging_down{background:url(../img/icons/ic-arrow.svg) 50% 50% no-repeat;background-size:2rem 1.1rem;width:9rem;height:4.5rem;position:relative;display:block}
.surfaces-paging_down:before{background-color:rgba(255,255,255,.5);content:'';display:block;width:5rem;height:.1rem;opacity:.5;position:absolute;top:0;left:50%;transform:translateX(-50%)}

.header.scroll{background-color:#fff;height:14rem;margin-bottom:0;padding-top:2rem;position:fixed;top:0;left:0;width:100%;z-index:20}
.header.scroll:after{display:none}
.header.scroll .header-logo{top:2rem}
.welcome .header.scroll .header-logo .logo-w{display:none}
.welcome .header.scroll .header-logo .logo-gr{display:block}
.welcome .header.scroll .header-menu a{color:#38424b}

.advantage{padding:7rem 0 10rem}
.advantage .container{background:url(../img/bg.gif) 50% 0 repeat-y;position:relative}
.advantage-one{display:flex;justify-content:center;margin-top:16rem}
.advantage-one:first-child{margin-top:0}
.advantage-one.left{flex-direction:row-reverse}
.advantage-one .subtitle{color:#8ec051;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:6rem;line-height:1.18;margin-bottom:4rem;max-width:52.6rem;text-transform:uppercase}
.advantage-one:first-child .subtitle{color:#00574a}
.advantage-one .pic{flex:0 0 70rem;width:70rem;margin-right:11.7rem}
.advantage-one .pic img{width:100%}
.advantage-one.left .pic{margin:0 0 0 11.7rem}
.advantage-content{padding-top:2.4rem}
.advantage-content .text{line-height:1.33;width:53rem;padding-left:6rem}
.advantage-content .text p{padding-top:4rem}
.advantage-content .btn{width:100%}

.view3D{color:#2e353b;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;margin-bottom:7rem;padding:5rem 0;position:relative}
.view3D:before{background-color:#d7d9db;content:'';display:block;width:calc(100% - 9.6rem);height:.1rem;position:absolute;top:0;left:4.8rem}
.view3D:after{background-color:#d7d9db;content:'';display:block;width:calc(100% - 9.6rem);height:.1rem;position:absolute;bottom:0;left:4.8rem}
.view3D h2{color:#00574a;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:4.8rem;max-width:84rem;margin-bottom:8rem;text-align:left}
.slider-3D{display:flex;justify-content:flex-end;padding-left:4.5rem;margin:0 -1.3rem}
.slider-3D .col-3{flex:0 0 33.333333%;width:33.333333%;padding:0 1.3rem}
.slider-3D .slide{margin-bottom:2rem;position:relative}
.icon-3D{background:#00574a url(../img/icons/ic-3d_w.svg) 50% 50% no-repeat;background-size:4rem 2.8rem;display:block;width:5.5rem;height:4.5rem}
.slider-3D .icon-3D{position:absolute;bottom:1rem;right:1rem;z-index:3}
.slider-3D .slide img{width:100%}
.slider-3D p{line-height:1.58;}

.about-us{overflow:hidden;display:flex;flex-direction:column;justify-content:space-between;padding:0 4.8rem}
.about-us_container{background-color:#2e353b;color:#fff;padding:6rem 5.1rem}
.about-us_top{margin-bottom:2rem;position:relative;text-align:center}
.about-us_top h2{font-family:'FuturaPT Book', Arial, Helvetica, sans-serif}
.about-us_logo{position:absolute;top:50%;left:1rem;transform:translateY(-50%)}
.about-us_logo img{width:12.6rem;height:auto}
.aboutus-slider{display:flex !important;justify-content:center}
.about-us_one{width:40.7rem;margin:0 .5rem;position:relative}
.about-us_one .pic{overflow:hidden;position:relative}
.about-us_one .pic img{width:100%}
.about-us_name{color:#fff;cursor:pointer;height:11rem;width:100%;line-height:1.5;padding-top:2rem}
.about-us_name p{color:#8ec051}
.pic-info{position:absolute;bottom:1.1rem;left:1rem;width:calc(100% - 2rem);height:8rem;overflow:hidden}
.about-us_link{background-color:#00574a;display:block;width:8rem;height:8rem;position:absolute;bottom:0;right:0;z-index:3}
.about-us_link:before{background:url(../img/icons/ic-arrow.svg) 50% 50% no-repeat;background-size:cover;content:'';display:block;width:2.1rem;height:1.2rem;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%) rotate(90deg);transition:all .2s linear}
.about-us_info{background-color:#00574a;color:#fff;display:block;font-size:2rem;height:8rem;line-height:1.5;padding:1.1rem 2rem;width:29.7rem;position:absolute;bottom:0;left:32rem;opacity:0;transition:all .2s linear}
.about-us_info a{color:#fff}
.about-us_link.rotate:before{transform:translate(-50%, -50%) rotate(-90deg)}
.about-us_info.show{left:0;opacity:1}

.map{display:flex;flex-direction:column;justify-content:space-between;padding:7rem 0 8.5rem}
.map .container{flex-grow:2}
.map h2{color:#8ec051;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;line-height:1.5;margin-bottom:3rem;text-align:left}
.map-scroll{margin-bottom:6rem}
.map-figure{background-color:#E0E1E2;min-width:102rem;position:relative}
.map-pic{width:100%}
.map-caption{background-color:#fff;display:flex;flex-flow:row wrap;font-size:1.8rem;padding:2.3rem 4.7rem;position:absolute;left:3rem;bottom:3rem;width:calc(100% - 6rem)}
.map-caption_block{margin-right:7rem;padding-top:.4rem}
.map-caption_block:nth-child(2){padding-top:0}
.map-caption_arrow{display:block;margin-bottom:1.5rem}
.arrow-green img, .arrow-brown img{width:4.3rem;height:.8rem}
.map-caption_arrow.arrow-yellow{margin-bottom:1.2rem}
.arrow-yellow img{width:4.3rem;height:1.5rem}
.map-content{display:flex;font-size:1.8rem;margin:0 -3.65rem}
.map-content_col{width:20%;padding:0 3.65rem}
.map-content_title{border-bottom:.2rem solid #8ec051;margin-bottom:3rem;padding-bottom:2.9rem}
.map-content_title img{height:2.4rem}
.icon-swipe{background:#2e353b url(../img/icons/ic-swipe.svg) 50% 50% no-repeat;background-size:2.4rem 2.6rem;display:block;width:4rem;height:4rem;}
.map-figure .icon-swipe{position:absolute;bottom:13.5rem;left:50%;transform:translateX(-50%);display:none}
.map-figure .map-link{display:block;font-size:1.8rem;width:21rem;height:3rem;line-height:3rem;padding:0 4rem 0 1.2rem;position:absolute;right:0;top:-4rem;text-transform:none}
.map-figure .map-link:after{background:url(../img/icons/ic-arrow-link.svg) 0 0 no-repeat;content:'';display:block;width:2rem;height:1.2rem;position:absolute;top:50%;right:1rem;transform:translateY(-50%)}
.map-link-row{display:none}

.footer-content{border-top:.2rem solid #2e353b;display:flex;justify-content:space-between;font-size:2.4rem;padding:2.2rem 0 2.1rem}
.footer-left{text-transform:uppercase}
.footer-left p{font-size:1.8rem;margin-bottom:.4rem}
.logo-batima{width:20.8rem;height:5.6rem}
.footer-right, .about-us_address{border-left:.1rem solid #d7d9db;padding:.8rem 0 2rem 5.7rem}
.footer-right{line-height:1.5}
.footer-right p{margin-bottom:2rem}
.footer-right a{color:#38424b}
.footer-bottom{border-top:.1rem solid #d7d9db;display:flex;justify-content:space-between;font-size:1.8rem;padding:3rem 0 2.7rem}
.footer-bottom a{color:#38424b;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;}

.iziModal{border-radius:0 !important;max-width:134.4rem !important;height:82rem !important;}
.iziModal-content, .modal-body{height:82rem}
.modal-body{padding:7rem 4rem}
.modal-body h2, .modal-body h3{margin-bottom:6rem;text-align:center}
.modal-close{background:url(../img/icons/ic-close.svg) 0 0 no-repeat;background-size:cover;display:block;width:2rem;height:2rem;position:absolute;top:3rem;right:3rem}
.modal-content{display:flex;justify-content:center;align-items:center;margin:0 auto;padding:0 1.5rem}
.modal-form{width:46.6rem}
.modal-form .btn{width:100%}
#downloadBrochure .modal-body{display:flex;padding:0}
#downloadBrochure .modal-pic, #downloadBrochure .modal-pic img{height:100%}
#downloadBrochure h3{text-align:left}

.contact{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:8rem 1.5rem}
.contact-form{display:flex;width:100%;max-width:107.4rem}
.contact-form_col{border-left:.1rem solid #d7d9db;width:50%;padding-left:8.5rem}
.contact-form_col:first-child{border-left:none;padding:0 8.5rem 0 0}
.question{color:#00574a;font-size:2.4rem;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;margin-bottom:3rem}
.form-item{margin-bottom:3.5rem;position:relative}
.form-label{color:#888d93;font-size:1.8rem;position:absolute;top:1.5rem;left:0}
.form-item.value .form-label{top:-1.6rem}
.form-radio{border:.2rem solid #414242;border-radius:.4rem;color:#4b5860;display:flex;justify-content:space-between;letter-spacing:.2rem;min-height:4rem;padding:.9rem 1.8rem 0;}
.form-radio_block{margin-bottom:2.6rem}
.form-radio_item label{cursor:pointer;display:block;font-size:1.8rem;min-height:3rem;padding:.5rem 0 2.8rem 4.8rem;position:relative}
.form-radio_item label:before{border:.1rem solid #d7d9db;border-radius:100%;content:'';display:block;width:3rem;height:3rem;position:absolute;top:0;left:0}
.form-radio_item input{display:none}
.form-radio_item input:checked+label:after{background-color:#00574a;border-radius:100%;content:'';display:block;width:1.4rem;height:1.4rem;position:absolute;top:.8rem;left:.8rem}
.form-checkbox label{display:block;font-size:1.8rem;padding-left:4rem;position:relative;text-align:left}
.form-checkbox label:before{border:.1rem solid #d7d9db;content:'';cursor:pointer;display:block;width:2rem;height:2rem;position:absolute;top:0;left:0}
.form-checkbox label:after{background:url(../img/icons/ic-check.svg) 0 0 no-repeat;background-size:cover;content:'';cursor:pointer;display:none;width:1.4rem;height:.9rem;position:absolute;top:.6rem;left:.3rem}
.form-checkbox input[type="checkbox"]:checked+label:after{display:block}
.form-checkbox a{color:#edc15c;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;}
.form-item.error input{border-bottom-color:#ed6666}
.form-item.error .error-text{position:absolute;top:-1.6rem;right:0;color:#ed6666;font-size:1.8rem}
.form-checkbox.error label{color:#ed6666}
.form-checkbox.error label:before{border-color:#ed6666}
.contact-form .btn{width:100%}
.contact-thankyou{width:100%;text-align:center}

.main{margin-bottom:8rem}
.main-h2{color:#8ec051;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:6rem;margin-bottom:6.8rem}
.main-pic{border-bottom:.1rem solid #d7d9db;margin-bottom:6.5rem;padding-bottom:3rem}
.main-pic img, .main-cols_pic .pic img{width:100%}
.main-subtitle{margin-bottom:6.5rem;padding-left:6rem}
.main-cols{display:flex;margin-bottom:10rem}
.main-cols_pic{flex:0 0 54%;padding-right:3rem;border-right:.1rem solid #E7E8EA}
.main-cols_pic .pic{margin-bottom:3rem}
.main-cols_pic .button{text-align:right}
.main-cols_pic .button .btn{display:inline-block;padding:0 4.6rem;width:auto}
.main-cols_text{flex:0 0 46%;line-height:1.33;padding-left:5rem}
.main-cols_text ul{border-left:.1rem dashed #E7E8EA}
.main-cols_text li{padding:4rem 0 0 3.7rem;position:relative}
.main-cols_text li:first-child{padding-top:0}
.main-cols_text li:before{background-color:#00574a;content:'';display:block;width:1rem;height:1rem;position:absolute;top:5.1rem;left:-.5rem}
.main-cols_text li:first-child:before{top:1.2rem}
.dark-block{background-color:#2e353b;color:rgba(255,255,255,.9);padding:7rem 6rem}
.dark-block_content{position:relative}
.dark-block_content:before{border-left:.1rem dashed #595D63;content:'';display:block;width:.1rem;height:100%;position:absolute;top:0;left:2.2rem}
.dark-block h3{background-color:#2e353b;margin-bottom:4rem;position:relative;text-transform:none}
.dark-block .main-subtitle{margin-bottom:8.3rem}
.dark-block .main-cols{flex-direction:row-reverse;margin:0 -6rem 10rem 0}
.dark-block .main-cols_text{padding:0 3rem 0 2.3rem}
.dark-block .main-cols_text ul{border-left:none}
.dark-block .main-cols_text li:before{background-color:#8ec051}
.dark-block .main-cols_pic{padding:0 0 0 3rem;border-left:.1rem solid #4B5157;border-right:none}
.dark-block .main-cols_pic .button{text-align:left}
.main-bottom{border-top:.1rem solid #4B5157;color:#8ec051;font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:3rem;padding-top:4.5rem}
.main h1+.subtitle{max-width:87rem}

.map-caption_cols{width:100%;display:flex;margin-top:2.2rem;padding:3rem 0 1rem;position:relative}
.map-caption_cols:before{background-color:#d7d9db;content:'';display:block;width:calc(100% + 9.4rem);height:.1rem;position:absolute;top:0;left:-4.7rem}
.col-4{flex:0 0 25%;width:25%;padding-left:2rem}
.map-caption_cols .col-4:first-child{padding-left:0}
.map-caption_cols .title{display:flex;font-family:'FuturaPT Bold', Arial, Helvetica, sans-serif;font-size:2.4rem;margin-bottom:1.5rem}
.map-caption_cols .title img{margin-right:2rem}
.map-caption_cols-duplicate{display:none}
.route-one{border-top:.1rem solid #d7d9db;display:flex}
.route-one_icon{flex:0 0 31%;width:31%;padding:4rem 0 8rem 3rem}
.icon-map2{width:3.2rem;height:3.6rem}
.icon-forest{width:3.2rem;height:2.4rem}
.icon-car{width:3.2rem;height:2.4rem}
.icon-plane{width:2.8rem;height:2.8rem}
.icon-shop{width:3.2rem;height:2.8rem}
.icon-bike{width:4rem;height:2.4rem}
.route-one_text{flex:0 0 38%;width:38%;line-height:1.33;padding:4rem 0 8rem;position:relative}
.route-one_text:before{background-color:#8ec051;content:'';display:block;width:100%;height:.1rem;position:absolute;top:-.1rem;left:0}

.surfaces{background-image:url(../img/bg-surface.jpg)}
.floor-modal{height:85.7rem !important;}
.floor-modal .iziModal-content, .floor-modal .modal-body{height:85.7rem}
.floor-modal .modal-body{padding:12rem 6rem 0}
.floor-modal .modal-content{display:block}
.floor-modal .modal-close{background:#867b69 url(../img/icons/ic-arrow.svg) 50% 50% no-repeat;background-size:2rem 1.2rem;width:25rem;height:6rem;top:0;left:50%;transform:translateX(-50%)}
.floor-modal h3{margin-bottom:3rem;text-align:left}
.floor-modal_info{line-height:1.58}
.floor-modal_info sup{top:-.7rem;font-size:1.3rem}
.floor-modal .btn-contact{width:25rem;position:absolute;bottom:4rem;left:50%;transform:translateX(-50%)}
.floor-slider{width:69rem;margin:0 auto}
.floor-slider .owl-stage-outer{margin-bottom:6.8rem}
.floor-slider .plan-floor img{width:55.2rem !important;margin:0 auto}
.floor-slider .vue img{width:68.4rem !important;margin:0 auto}
.floor-slider .owl-nav{width:33.2rem;height:3rem;margin:0 auto;position:relative}
.floor-slider .owl-nav button.owl-next, .floor-slider .owl-nav button.owl-prev{position:absolute;top:0;width:4.2rem;height:3rem;background:url(../img/icons/ic-arrow_vert-gr.svg) 100% 50% no-repeat;background-size:1.2rem 2.1rem;border-left:.1rem solid #d7d9db;}
.floor-slider .owl-nav button.owl-next span, .floor-slider .owl-nav button.owl-prev span{display:none}
.floor-slider .owl-nav button.owl-prev{left:0;transform:rotate(180deg)}
.floor-slider .owl-nav button.owl-next{right:0}
.floor-slider .owl-dots{display:flex;position:absolute;left:50%;bottom:1.4rem;transform:translateX(-50%)}
.floor-slider button.owl-dot{background-color:#d7d9db}
.floor-count{position:absolute;right:6rem;top:50%;transform:translateY(-50%);text-align:center}
.floor-number{font-size:3.6rem;line-height:1;opacity:.5}
.floor-count_up, .floor-count_down{background-color:transparent;display:block;width:100%;height:3.1rem;margin-top:3.5rem;position:relative}
.floor-count_up:before{background:url(../img/icons/ic-arrow_vert-gr.svg) 0 0 no-repeat;background-size:cover;content:'';display:block;width:1.2rem;height:2.1rem;position:absolute;top:0;left:50%;transform:translateX(-50%) rotate(-90deg)}
.floor-count_down{margin:0 0 3.5rem}
.floor-count_down:before{background:url(../img/icons/ic-arrow_vert-gr.svg) 0 0 no-repeat;background-size:cover;content:'';display:block;width:1.2rem;height:2.1rem;position:absolute;bottom:0;left:50%;transform:translateX(-50%) rotate(90deg)}
.floor-count_floor{border:.1rem solid #d7d9db;font-size:1.4rem;line-height:1.14;letter-spacing:.1rem;padding:.5rem 1rem;width:10rem}
.floor-count_floor p{font-family:'FuturaPT Medium', Arial, Helvetica, sans-serif;font-size:6rem}
.icon-compass{background:url(../img/icons/pic-compass.svg) 0 0 no-repeat;background-size:cover;display:block;width:14.2rem;height:14.6rem}
.floor-modal .icon-compass{position:absolute;left:8rem;bottom:6rem}
.floor-svg img{width:100%}
.floor-0{display:block;position:absolute;left:0;bottom:15%;width:87%}
.floor-1{display:block;position:absolute;left:0;bottom:30.2%;width:76.8%}
.floor-2{display:block;position:absolute;left:0;bottom:35.8%;width:76.8%}
.floor-3{display:block;position:absolute;left:0;bottom:42.7%;width:76.8%}
.floor-4{display:block;position:absolute;left:0;bottom:49.56%;width:86.9%}
.floor-5{display:block;position:absolute;left:0;bottom:56.32%;width:90%}
.floor-6{display:block;position:absolute;left:31.8%;bottom:63.2%;width:58.1%}

.hide{display:none !important;}

@media screen and (max-width: 1443px) {
    .container, .about-us, .welcome-bottom, .surfaces-bottom{padding:0 2.5rem}
    .welcome{padding:15rem 2.5rem}
    .header:after{left:2.5rem;width:calc(100% - 5rem)}
    .header-logo, .pic-view3D{left:2.5rem}
    .header .btn{right:2.5rem}
    .view3D:before, .view3D:after{width:calc(100% - 5rem);left:2.5rem}
    .map-content{margin:0 -2.5rem}
    .map-content_col{padding:0 2.5rem}
}

@media screen and (max-width: 1399px) {
    .advantage-one{justify-content:space-between}
    .advantage-one .pic, .advantage-one.left .pic{margin: 0}
    .about-us_container{padding:6rem 3rem}
    .about-us_one{width:37rem}
    .about-us_info{width:26.7rem}
    .map-caption_cols{display:none}
    .map-caption_cols-duplicate{display:flex;margin-top:-2.5rem;padding:0 0 6rem}
    .map-caption_cols-duplicate:before{display:none}
}

@media screen and (max-width: 1299px) {
    .advantage .container{background:none}
    .advantage-one, .advantage-one.left{flex-direction:column;align-items:center}
    .advantage-one .pic, .advantage-one.left .pic{flex:0 0 100%;width:100%;margin:0 0 3rem}
    .iziModal{height:100vh !important;max-width:110.7rem !important;}
    .iziModal-content, .modal-body{height:100%}
    .modal-pic{width:35rem;overflow:hidden}
    .modal-content{height:65rem}
    .floor-modal, .floor-modal .iziModal-content, .floor-modal .modal-body{height:auto !important}
    .floor-modal .modal-content{height:100%}
    .floor-modal .btn-contact{bottom:auto;margin-top:3rem;position:relative}
}

@media screen and (max-width: 1199px) {
    .welcome{padding-bottom:0}
    .pic-view3D, .welcome-bottom{position:relative}
    .pic-view3D{bottom:auto;left:auto;margin:0 auto 3rem}
    .surfaces .pic-view3D{position:absolute;bottom:14rem;left:2.5rem}
    .welcome-bottom{display:block;width:43rem;height:27rem;padding:0;bottom:0;left:auto;margin:0 auto}
    .welcome-bottom_main, .welcome-bottom_item{height:9rem;width:100%}
    .welcome-bottom .welcome-bottom_item:last-child:before{left:50%;top:0;transform:translate(-50%, 0);width:90%;height:.1rem}
    .about-us_container{padding:3rem}
    .about-us_top{text-align:center}
    .about-us_logo{display:block;position:relative;left:auto;top:auto;transform:none;margin:0 auto 2.2rem;width:12.6rem;}
    .map-content{margin:0 -2rem}
    .map-content_col{padding:0 2rem}
    .footer-right, .about-us_address{padding-left:3rem;width:33%}
    .contact-form_col{padding-left:4rem}
    .contact-form_col:first-child{padding:0 4rem 0 0}
    .main-h2{font-size:5rem}
    .route-one_icon{flex:0 0 45%;width:45%}
    .route-one_text{flex:0 0 45%;width:45%}
}

@media screen and (max-width: 1140px) {
    .btn-mobile, .btn-brochure{display:block}
    .mobile-menu{background-color:#fff;padding:8.5rem 2.5rem;text-align:left;position:fixed;top:0;left:0;width:100%;height:100%;z-index:100;display:none}
    .mobile-menu .header-menu ul{border-top:.1rem solid rgba(56,66,75,.2);display:block}
    .mobile-menu .header-menu li{border-bottom:.1rem solid rgba(56,66,75,.2);margin:0;padding:2rem 0}
    .mobile-menu .header-menu a{color:#38424b}
    .mobile-menu .close{background: #d7d9db url(../img/icons/ic-close.svg) 50% 50% no-repeat;background-size:2rem 2rem;display:block;width:4rem;height:4rem;position:absolute;top:2.5rem;right:2.5rem}
    .mobile-menu .btn{position:absolute;top:auto;bottom:7.3rem;right:50%;transform:translateX(50%);width:29rem;height:4.8rem;line-height:4.8rem}
    .mobile-menu.open{display:block}
    .mobile-menu .header-menu .active:after{display:none}
    .header-logo{top:2.3rem}
    .map-scroll{margin:0 -2.5rem 6rem;overflow-y:hidden;overflow-x:auto}
    .iziModal{max-width:92.7rem !important;margin:0 auto}
    .footer-content{font-size:2.2rem}
    .floor-modal .modal-body{padding:12rem 3rem 0}
    .floor-count{right:3rem}
    .floor-modal .icon-compass{left:3rem;bottom:0}
}

@media screen and (max-width: 1020px) {
    .map-figure .icon-swipe{display:block}
}

@media screen and (max-width: 991px) {
    .brochure-label{width:4rem;height:4rem;padding-left:4rem;overflow:hidden;top:4.3rem;right:8.5rem}
    .brochure-label:before{width:4rem;height:4rem}
    .brochure-label:after{top:.6rem;left:.9rem}
    .welcome{background:url(../img/bg-main.jpg) 50% 0 no-repeat;background-size:cover;color:#fff;display:block;font-size:3rem;min-height:0;padding:18.3rem 1.5rem 0;text-align:center}
    .surfaces{background-image:url(../img/bg-surface.jpg);min-height:100vh}
    .welcome-bottom{display:block;height:auto}
    .header{height:12rem;margin-bottom:4rem;padding:0;z-index:18}
    .header-logo img{width:6rem}
    .header-map{display:none}
    .header.scroll{height:11.4rem}
    .contact{padding:8rem 1.5rem 4rem}
    .contact-form{display:block;width:100%;max-width:47rem;margin:0 auto}
    .contact-form_col{border-left:none;width:100%;padding:0}
    .contact-form_col:first-child{padding:0}
    .contact-form_col:nth-child(2) > div{border-top:.1rem solid #8ec051;padding-top:2rem}
    .form-radio_block{margin-bottom:2rem}
    .question{font-size:2.4rem;margin-bottom:3rem}
    .view3D{font-size:2rem}
    .slider-3D{flex-direction:column;align-items:center;margin:0;padding-left:0}
    .slider-3D .col-3{flex:1 0 0;width:43rem;padding:3rem 0 0}
    .slider-3D .col-3:first-child{padding:0}
    .aboutus-slider{display:block !important;}
    .aboutus-slider .owl-nav button.owl-next, .aboutus-slider .owl-nav button.owl-prev{background:url(../img/icons/ic-arrow.svg) 0 0 no-repeat;background-size:cover;position:absolute;bottom:0;left:0;display:block;width:3.2rem;height:1.8rem;transform:rotate(90deg)}
    .aboutus-slider .owl-nav button.owl-next.disabled, .aboutus-slider .owl-nav button.owl-prev.disabled{opacity:.5}
    .aboutus-slider .owl-nav button.owl-next span, .aboutus-slider .owl-nav button.owl-prev span{display:none}
    .aboutus-slider .owl-nav button.owl-next{left:auto;right:0;transform:rotate(-90deg)}
    .aboutus-slider .owl-stage-outer{margin-bottom:3rem}
    .aboutus-slider .owl-dots{height:3.2rem;text-align:center}
    .aboutus-slider button.owl-dot{background-color:#00574a;border-radius:100%;display:inline-block;margin:0 .8rem;width:1rem;height:1rem}
    .aboutus-slider button.owl-dot.active{background-color:#8ec051}
    .about-us_one{margin:0 auto;width:100%;max-width:40.7rem}
    .map{padding-bottom:2rem}
    .map-figure{height:46.7rem;}
    .map-pic{width:100%;height:100%;object-fit:cover}
    .map-caption{padding:1.3rem 2.5rem 1.4rem;left:2.5rem;bottom:2.5rem;width:calc(100% - 5rem)}
    .map-content{flex-flow:row wrap;margin:0 -2rem}
    .map-content_col{width:33.333333%;margin-bottom:5rem}
    .map-figure .icon-swipe{bottom:11.1rem}
    .footer-bottom{display:block;line-height:1.33;text-align:center}
    .footer-bottom p:first-child{margin-bottom:1rem}
    .main-h2{font-size:4rem}
    .main-cols{display:block}
    .main-cols_pic{border-right:none;margin-bottom:3rem;padding-right:0}
    .main-cols_text{border-left:.1rem solid #d7d9db;padding-left:3.7rem}
    .dark-block{margin:0 -2.5rem;padding:3rem 2.5rem}
    .dark-block_content:before{display:none}
    .dark-block .main-cols{flex-direction:column;margin:0 0 6rem}
    .dark-block .main-cols_text{border-left:none;margin-bottom:3rem;padding-right:0}
    .dark-block .main-cols_text ul{border-left:.1rem dashed #595D63}
    .dark-block .main-cols_pic{border-left:none;padding-left:0;position:relative}
    .modal-content{display:block;height:auto;padding:0}
    #downloadBrochure .modal-pic{display:none}
    #downloadBrochure .modal-form{width:100%}
    #downloadBrochure .modal-body{padding:7rem 4rem}
    .surfaces-bottom_info{left:4.4rem;top:1.2rem}
    .surfaces-bottom_info p{font-size:4.8rem}
    .floor-modal .icon-compass{display:none}
    .floor-slider{width:42rem}
    .floor-slider .plan-floor img, .floor-slider .vue img{width:42rem !important;}
    .floor-modal_info{margin-bottom:3rem}
}

@media screen and (max-width: 960px) {
    .iziModal{max-width:80rem !important;}
}

@media screen and (max-width: 767px) {
    h2{font-size:4rem;line-height:1.05;margin-bottom:3rem}
    .welcome{position:relative}
    .welcome:before{background-color:rgba(0,0,0,.1);content:'';display:block;width:100%;height:100%;position:absolute;top:0;left:0;z-index:0}
    .welcome-center, .welcome-bottom{position:relative;z-index:2}
    .welcome-center .h1-top, .welcome-center p{font-size:2rem}
    .welcome-center .h1-top{margin-bottom:1rem}
    .welcome-center .h1-main{font-size:7.5rem}
    .welcome-center .h1-bottom{font-size:4.5rem}
    .welcome-center p{max-width:41rem}
    .advantage-one{margin-top:9rem}
    .brochure-title{font-size:3rem;top:10.5rem}
    .brochure-title p{font-size:4rem}
    .view3D h2{font-size:4rem;margin-bottom:4rem;}
    .about-us_title{font-size:3rem}
    .about-us_title p{font-size:4rem}
    .map-content{margin:0 -1.5rem}
    .map-content_col{width:50%;padding:0 1.5rem}
    .footer-content{display:block;padding-bottom:0}
    .footer-left{margin-bottom:2rem;text-align:center}
    .logo-batima{margin:0 auto}
    .footer-right, .about-us_address{border-left:none;border-top:.1rem solid #d7d9db;line-height: 1.78;padding-top:2rem;width:100%}
    .map-caption_cols-duplicate{flex-flow:row wrap;padding:0 0 2rem}
    .col-4{flex:0 0 50%;width:50%;margin-bottom:4.5rem}
    .col-4:nth-child(2n){padding:0 0 0 2rem}
    .col-4:nth-child(3){padding-left:0}
    .btn-plans{width:32rem}
    .floor-modal .modal-body{position:relative}
    .floor-count{position:relative;top:auto;right:0;background-color:#2e353b;color:#fff;margin:3rem -3rem 0;padding:0 3rem 9rem;transform:none}
    .floor-number{display:none}
    .floor-count_floor{border:none;width:100%;padding:3rem 0}
    .floor-count_up, .floor-count_down{position:absolute;top:3rem;margin:0;width:3rem;height:8.3rem;border-top:.1rem solid rgba(255,255,255,.25);border-bottom:.1rem solid rgba(255,255,255,.25)}
    .floor-count_down{left:3rem}
    .floor-count_down:before{background:url(../img/icons/ic-arrow.svg) 0 0 no-repeat;background-size:cover;width:2rem;height:1.1rem;top:50%;transform:translate(-50%, -50%)}
    .floor-count_up{right:3rem}
    .floor-count_up:before{background:url(../img/icons/ic-arrow.svg) 0 0 no-repeat;background-size:cover;width:2rem;height:1.1rem;top:50%;transform:translate(-50%, -50%) rotate(180deg)}
    .floor-modal .btn-contact{margin:0;position:absolute;left:50%;bottom:3rem;transform:translateX(-50%);z-index:2}
}

@media screen and (max-width: 639px) {
    h1{font-size:4rem}
    .brochure-label{top:3.5rem;right:7.5rem}
    .mobile-menu{padding:8.5rem 1.5rem}
    .container, .about-us, .welcome-bottom{padding:0 1.5rem}
    .header{padding-bottom:2.5rem;justify-content:center}
    .header:after{left:1.5rem;width:calc(100% - 3rem)}
    .header.scroll{height:10.4rem}
    .header-logo, .header.scroll .header-logo{left:1.5rem;top:1.5rem}
    .btn-mobile{top:1.5rem;right:1.5rem}
    .advantage-one .subtitle{font-size:4rem}
    .view3D:before, .view3D:after{width:calc(100% - 3rem);left:1.5rem}
    .about-us{padding:0}
    .map-scroll{margin:0 -1.5rem 4rem}
    .map-caption{left:1.5rem;bottom:1.5rem;width:calc(100% - 3rem)}
    .map-figure .icon-swipe{bottom:10.1rem}
    .contact{padding:4rem 1.5rem 2rem}
    .footer-right, .about-us_address{padding-left:0}
    .main-h2{font-size:3rem;margin-bottom:3rem;text-transform:uppercase}
    .dark-block{margin:0 -1.5rem;padding:2rem 1.5rem 5rem}
    .map-caption_cols-duplicate{margin-top:-.5rem}
    .map-figure .map-link{display:none}
    .map-link-row{display:block;background-color:#E0E1E2;margin:-4rem -1.5rem 3.4rem;padding:0 1.5rem 1.5rem;text-align:center}
    .map-link{display:inline-block;width:auto;padding:0 6rem;position:relative}
    .map-link:after{background:url(../img/icons/ic-arrow-link.svg) 0 0 no-repeat;content:'';display:block;width:2rem;height:1.2rem;position:absolute;top:50%;right:2rem;transform:translateY(-50%)}
    .surfaces .pic-view3D{position:fixed;bottom:auto;top:1.5rem;left:50%;transform:translateX(-50%);width:5rem;height:4rem;z-index:20}
    .surfaces .pic-view3D .icon-3D.dark{right:0;bottom:0;width:100%;height:100%}
    .surfaces .pic-view3D:before, .surfaces .pic-view3D img{display:none}
    .surfaces{min-height:calc(100vh - 13.3rem);margin-bottom:13.3rem}
    .surfaces-bottom{bottom:-13.3rem;flex-direction:column;background-color:#00574a;height:16.3rem;padding:0 1.5rem}
    .surfaces-paging{background-color:transparent;width:100%;position:absolute;left:0}
    .surfaces-paging_down, .surfaces-paging_up{width:2rem;height:6.4rem;position:absolute;top:2rem;border-top:.1rem solid rgba(255,255,255,.25);border-bottom:.1rem solid rgba(255,255,255,.25)}
    .surfaces-paging_down{left:1.5rem}
    .surfaces-paging_down:before{display:none}
    .surfaces-paging_up{right:1.5rem}
    .btn-plans{position:relative;left:auto;transform:none;margin:0;height:4rem;font-size:1.6rem}
    .btn-plans:after{transform:translateY(-50%) rotate(-90deg);width:1.6rem;height:.8rem}
    .surfaces-bottom_info{position:relative;left:auto;top:auto;margin-bottom:2rem}
    .surfaces-bottom_main{margin-right:0;padding-top:2rem}
}

@media screen and (max-width: 575px) {
    body{font-size:1.8rem}
    h1, h2{font-size:3rem}
    h1{margin-bottom:3rem}
    .btn{height:4rem;font-size:1.6rem}
    a.btn{line-height:4rem}
    .header, .welcome .header{margin-bottom:2rem}
    .welcome-center .h1-top{font-size:1.6rem}
    .welcome-center .h1-main{font-size:4rem}
    .welcome-center .h1-bottom{font-size:3rem}
    .welcome-bottom, .welcome-bottom_main{font-size:1.8rem}
    .welcome-bottom_item, .welcome-bottom_main{height:7.5rem}
    .welcome-bottom_main .number{font-size:2.4rem}
    .pic-view3D{width:19rem;height:9.8rem}
    .icon-3D.dark{background-size:3.4rem 2.4rem;width:4.5rem;height:3.7rem}
    .advantage, .view3D{padding-bottom:3rem}
    .advantage-one{margin-top:3rem}
    .advantage-content{padding-top:0}
    .advantage-one .subtitle{font-size:3rem;line-height:1.4;margin-bottom:2.4rem}
    .advantage-content .text, .main-subtitle{width:100%;padding-left:2.3rem}
    .advantage-content .text p{margin-left:-2.3rem;padding-top:2.4rem}
    .view3D h2{font-size:3.4rem;margin-bottom:3rem}
    .slider-3D .col-3{width:100%}
    .about-us_container{padding:3rem 1.5rem}
    .about-us_top{text-align:left}
    .about-us_logo{margin:0 0 3rem}
    .about-us_top h2{font-size:3rem;text-align:left}
    .about-us_name{font-size:2.4rem}
    .about-us_link{width:4rem;height:4rem}
    .about-us_link:before{width:1.6rem;height:.9rem}
    .about-us_info{font-size:1.6rem;height:7rem}
    .about-us_info p{font-size:1.8rem}
    .map h2{font-size:3rem}
    .footer-content{border-top-width:.5rem;font-size:1.8rem}
    .footer-bottom{padding:2rem 0}
    .modal-close{background-color:#d7d9db;background-position: 50% 50%;background-size:2rem 2rem;top:1.5rem;left:1.5rem;right:auto;width:4rem;height:4rem}
    .modal-body, #downloadBrochure .modal-body{padding:7.5rem 1.5rem}
    .modal-body h2, .modal-body h3{margin-bottom:2.5rem;text-align:left}
    .main-pic, .main-subtitle, .main-cols, .dark-block .main-subtitle{margin-bottom:4rem}
    .main-cols_pic .button .btn{display:block}
    .main, .dark-block h3, .dark-block .main-cols{margin-bottom:3rem}
    .main-bottom{font-size:2.4rem;padding-top:2.5rem}
    .dark-block_content:before{left:0}
    .map-caption_cols-duplicate{padding:0}
    .map-caption_cols .title{font-size:1.8rem}
    .map-caption_cols .title img{margin-right:1rem}
    .col4{margin-bottom:3.2rem}
    .map-link{display:block;padding:0 5rem}
    .floor-modal .modal-body{padding:6rem 1.5rem 0}
    .floor-modal .modal-close{height:4rem}
    .floor-count{margin:2rem -1.5rem 0;padding:0 1.5rem 6rem}
    .floor-modal .btn-contact{bottom:2rem}
    .btn-plans{width:100%}
}

@media screen and (max-width: 480px) {
    .header{height:9rem;}
    .header.scroll{height:7.8rem}
    .header-logo img{width:4rem}
    .welcome{margin-bottom:22.5rem;padding-bottom:.1rem}
    .welcome-bottom{width:100%;position:absolute;left:0;bottom:-22.5rem;margin:0;padding:0}
    .welcome-bottom .number{display:block}
    .welcome-bottom_main, .welcome-bottom_item{justify-content:flex-start}
    .welcome-bottom .welcome-bottom_item:last-child:before{left:1.5rem;width:calc(100% - 3rem);transform:none}
    .dark-block{padding:1.5rem}
    .dark-block .main-cols .btn{padding:0}
    .route-one_icon{flex:0 0 5rem;width:5rem;padding-left:0}
    .route-one_text{flex-grow:2;width:auto;padding:3rem 0}
    .icon-map2{width:2.4rem;height:2.7rem}
    .icon-forest{width:2.4rem;height:1.8rem}
    .icon-car{width:2.4rem;height:1.8rem}
    .icon-plane{width:2.4rem;height:2.1rem}
    .icon-shop{width:2.4rem;height:2.1rem}
    .icon-bike{width:2.4rem;height:1.4rem}
    .floor-slider, .floor-slider .owl-nav{width:28.3rem}
    .floor-slider .plan-floor img, .floor-slider .vue img{width:20rem !important}
    .floor-slider .owl-nav button.owl-next, .floor-slider .owl-nav button.owl-prev{width:3.2rem}
    .floor-slider button.owl-dot{width:4rem;margin:0 1.5rem}
    .floor-modal .modal-close, .floor-modal .btn-contact{width:calc(100% - 3rem)}
    .floor-count_floor{padding:2.2rem 0}
    .floor-count_floor p{font-size:4.8rem}
    .floor-count_up, .floor-count_down{top:2.2rem;width:2rem;height:6.4rem}
    .floor-count_up{right:1.5rem}
    .floor-count_down{left:1.5rem}
    .floor-count_down:before, .floor-count_up:before{width:1.6rem;height:.8rem}
}
