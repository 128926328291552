.iziModal {
    z-index: 999; border-radius: 3px; max-width: 600px; display: block; height: 887px;
    overflow-y: auto;
}
.iziModal .btn {
    position: relative;
    top: initial;
    right: initial;
}
.floor-modal .btn {
    position: absolute;
}
.iziModal {
    text-align: left;
    color: #38424b
}
.iziModal-overlay {
    background-color: rgba(56, 66, 75, 0.8); z-index: 997;
}
.brochure-label {
    cursor: pointer;
}
.form-label {
    transition: top 200ms;
}
.form-label-top {
    top: -1rem;
}

.error-input {
    position: absolute;
    font-size: 1.5rem;
    margin-top: 0.4rem;
}
.wrapper-img {position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;}
.wrapper-img img {
    object-fit: fill;
}
.header {
    z-index: 10;
}
.surfaces {
    min-height: 100vh;
}
.mobile-menu {
    display: block;
}
.route-one_text {
    text-align: left;
}
.surfaces .welcome-bottom, .surfaces-bottom {
    position: fixed;
}
.floor-modal_info {
    font-size: 2.6rem;
}
.extraText {
    position: absolute;
    right: 7rem;
    bottom: 5rem;
    width: 29rem;
    font-size: 2rem;
    text-align: right;
}
.main-h2 {
    font-size: 4rem;
}
.infoDiv {
    bottom: 5rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid black;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}
.wrapperInfo {
    background-color: rgba(46, 53, 59, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
}
.blockInfo {
    z-index: 100000;
    /* background: white; */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10rem;
}
.blockInfo .extraText {
    background-color: white;
    text-align: center;
    padding: 1rem;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.triangle-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 12px solid white;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}
.header-menu a.active {
    color: #00574a;
    font-weight: 600;
}
.brochure-label {
    opacity: 0.4;
}
.brochure-label:hover {
    opacity: 1;
}
h1 {
    font-size: 4rem;
}
.plan-floor img {
    max-width: 80%;
    margin: auto;
    cursor: zoom-in;
}
.zoomimg {
    cursor: zoom-out;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: scroll;
}
.zoomimg img {
}
.js-image-zoom__zoomed-area {
    z-index: 9999999;
}
.js-image-zoom__zoomed-image {
    z-index: 9999999;
}
.floor-slider .owl-dots {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 1.4rem;
    transform: translateX(-50%);
}
.floor-slider button.owl-dot {
    background-color: #848A90;
    width: 6rem;
    height: .2rem;
    margin: 0 1rem;
}

.floor-slider button.owl-dot {
    background-color: #d7d9db;
}
.floor-slider button.owl-dot.active {
    background-color: #8ec051;
}
.floor-slider .owl-nav {
    width: 46rem;
}
.slider-3D-title .item {
    color: #38424b;
    font-weight: 600;
}
.tooltipView {
    background-color: #2e353b;
    padding: 4px 9px;
    position: absolute;
}
.titleFloor {
    position: absolute;
    color: #00574a;
    text-align: center;
    left: 0;
    right: 0;
    top: -20px;
    /* font-size: 12px; */
    font-weight: 500;
}
.circle {
    background-color: #8ec051;
    padding: 1rem;
    font-size: 1.5rem;
    position: absolute;
    z-index: 1000000;
    transform: translateX(-50%);
    color: #00574a;
    font-weight: 600;
}
.circle .triangle-down {
    top: 100%;
    border-top: 12px solid #8ec051;
}
.iziModal .pic-view3D {
    left: initial;
    right: 6rem;
    bottom: 4rem;
}
.floorLink {
    position: absolute;
    z-index: 99999999999;
    bottom: 0;
    right: 0;
}
.withoutBorder::after {
    content: none;
}
.header.scrollBorder {
    border-bottom: 1px solid #D7D9DB;
}

.aboutCon {
    margin-top: 20rem;
}
.belleterre .question {
    margin-bottom: 1rem;
}
.belleterre a {
    color: #38424b
}
.text-belleterre {
    font-size: 2rem;
}
.icTurn {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00574a;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: animTurn;
    animation-duration: 1000ms;
    animation-delay: 2000ms;
    animation-fill-mode: both;
}
.icTurn img {
    width: 14rem;
}
.surfaces {
    background-image: none;
}
.about-us_one {
    width: 35rem;
}
@keyframes animTurn {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        z-index: 0;
    }
}
@media screen and (max-width: 991px) {
    .brochure-label {
        opacity: 1;
    }
}
@media screen and (max-width: 767px) {
    .iziModal .pic-view3D {
        display: none;
    }
    .titleFloor {
        top: 100%;
        font-size: 22px;
    }
    .floor-modal_info {
        font-size: 2rem;
    }
}
@media screen and (max-width: 639px) {
    .surfaces-bottom {
        bottom: 0;
    }
    .main-cols_text {
        text-align: justify;
    }
    .floor-slider button.owl-dot {
        margin: 0 0.4rem;
    }
    .floor-slider .owl-nav {
        width: 28.3rem;
    }
    .aboutCon {
        margin-top: 12rem;
    }
    .main-h2 {
        font-size: 2.6rem
    }
    h1 {
        font-size: 2.6rem
    }
    .header.scroll {
        background-color: rgba(255, 255, 255, .8) !important;
    }
}

@media screen and (max-width: 575px) {
    .floor-count {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .floor-modal .btn {
        position: fixed;
    }
    .floor-count_up {
        right: 3rem;
    }
    .floor-count_down {
        left: 3rem;
    }
}
